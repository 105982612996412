import { Button } from 'components/Common';
import { ReactComponent as Starreward } from 'assets/svg/Starreward.svg';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { IoInformationCircleOutline } from 'react-icons/io5';

const GET_USER_MAKER_TAKER_ORDERS = gql`
query RewardsForUsers($user: String) {    
    userAccounts(where: {address: $user}) {
        makerOrders(where: {status_not: Cancelled}) {
          id          
        }
        takerOrders {
          id
        }
        address
      }
  } 
`;
const Rewards = () => {  
    const [takerPoints, setMarketOrderPoints] = useState(0);
    const [makerPoints, setLimitOrderPoints] = useState(0);
    const { isConnected, address } = useAccount();
    const { loading, error, data } = useQuery(GET_USER_MAKER_TAKER_ORDERS, {
        variables: { user: address}
    });
    const MAKER_ORDER_POINTS = 20;
    const TAKER_ORDER_POINTS = 10;  
    useEffect(() => {
      if (!loading && !error && data) {
        //console.log("Reward Points",data.userAccounts[0],data.userAccounts[0]?.makerOrders?.length, data.userAccounts[0]?.takerOrders?.length);
        setLimitOrderPoints((data.userAccounts[0]?.makerOrders?.length || 0) * MAKER_ORDER_POINTS)
        setMarketOrderPoints((data.userAccounts[0]?.takerOrders?.length || 0) * TAKER_ORDER_POINTS);
      }
    }, [loading, error, data]);
    function handleClick () {
        //console.log("clicked I of Reward");
        window.open('https://nftfn.gitbook.io/nftfn-1/', '_blank');
    }
    return(
        <div>
            
            {isConnected ?(
                    <div
                        style={{ display: 'flex', gap: 12 }}
                        className="module-border-wrap rounded-[15px] w-full h-[32px]"
                    >
                        <Button
                            
                            type="button"
                            className={`bg-[#10141B] rounded-[15px] flex justify-center items-center py-[4px] px-[4px] gap-[4px] font-normal text-[14px] text-white focus:outline-none `}
                            variants="none"
                            isFullWidth
                            style={{ borderRadius: '16px' }}
                        >                            
                                <Starreward 
                                    style={{ height: '24px', width: '24px', color: '#AEAFC1' }}
                                />
                            
                            <div className="module-border-wrap rounded-[15px] h-[24px] ">
                                <div className="bg-[#141820] rounded-[15px] px-[8px] text-[14px] ">
                                    {takerPoints + makerPoints}
                                </div>
                            </div>
                            <div className="module-border-wrap rounded-[15px] h-[24px]">
                                <div className="flex justify-center items-center bg-[#141820] rounded-[15px] px-[8px] text-[14px]">
                                    Points                                    
                                    <span className="ml-1 mt-1">
                                        <IoInformationCircleOutline onClick={() => handleClick()}/>
                                    </span>                   
                                </div>
                            </div>
                        </Button>
                    </div>
                ):(<div></div>)}
            
        </div>
    );
};
export default Rewards;
