import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface CustomTooltipProps {
    title: any;
    children: React.ReactNode;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '13.5px',
        background: '#11151B',
        border: '1px solid #CECFD1',
        padding: '5px 10px',
        borderRadius: '5px',
        fontWeight: 'normal',
        fontFamily: 'Inter'
    }
});
function CustomTooltip({ title, children }: CustomTooltipProps) {
    return (
        <div>
            {children === '0.00' || children === '$0.00' ? (
                children
            ) : (
                <div>
                    <CustomWidthTooltip title={title} placement="top" arrow>
                        <span className="cursor-pointer">{children}</span>
                    </CustomWidthTooltip>
                </div>
            )}
        </div>
    );
}

export default CustomTooltip;
