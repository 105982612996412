import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import { StartingValuesInterface } from '../../../interfaces/DatePage';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertToEpoch } from 'utils/epochTime';
import { DailyUsers } from "./DailyUsers";
import { useSkip } from 'store';
import { generateWeeklyMarkers } from './DailyUsers';
import { getTime } from '../utils/date';
import { ButtonInterface } from '../../../interfaces/DatePage';
import { ReactComponent as Calender } from 'assets/svg/calender.svg';
import Button from '../Button';

const preSetButtons: Array<ButtonInterface> = [    
    {
        text: '7D',
        isActive: false
    },
    {
        text: '2W',
        isActive: true
    },
    {
        text: '1M',
        isActive: false
    },
    {
        text: '3M',
        isActive: false
    },
];

//NOTE: The dayjs shall consider the timestamp in milliseconds, subgraph needs in seconds
export const WeeklyUsers = () => {    
    const {setSkipVar} = useSkip();
    const endOfTwoWeeks = dayjs().endOf('day');
    const startOfTwoWeeks = dayjs().subtract(2, 'week').startOf('day');

    const [startingDateValues, setStartingDateValues] = useState<StartingValuesInterface>({
        firstDateValue: dayjs(startOfTwoWeeks.toDate()),
        firstEpochValue: startOfTwoWeeks.toDate().getTime() / 1000,
        secondDateValue: dayjs(endOfTwoWeeks.toDate()),
        secondEpochValue: endOfTwoWeeks.toDate().getTime() / 1000
    });
    const [buttons, setButtons] = useState<Array<ButtonInterface>>(preSetButtons);


    const changeDate = (val: any, buttonType: '1' | '2') => {
        const epochTime = convertToEpoch(val?.$y, val?.$M, val?.$D, val?.$H, val?.$m, val?.$s, val?.$ms);
        buttonType === '1'
            ? setStartingDateValues({
                ...startingDateValues,
                firstEpochValue: epochTime
            })
            : setStartingDateValues({
                ...startingDateValues,
                secondEpochValue: epochTime
            }); 
        setSkipVar(0);                      
    };
    //console.log("first epoch, 2nd epoch", startingDateValues.firstEpochValue,startingDateValues.secondEpochValue);
    const {seriesCustomDaily, stateCustomDaily} = DailyUsers(startingDateValues.firstEpochValue,startingDateValues.secondEpochValue);
    const weeklyMarkers = generateWeeklyMarkers(stateCustomDaily.options.xaxis.categories, seriesCustomDaily[0].data);
    //console.log("weeklyMarkers",weeklyMarkers);
    stateCustomDaily.options = {
        ...stateCustomDaily.options,
        annotations: {
            xaxis: weeklyMarkers,
        },
        };
    const handleButtonSelect = (button: '7D' | '2W' | '1M' | '3M' ) => {
    
        const fromDate = getTime(button);
        //console.log("Date ",fromDate?.Date.toString());
        
        for (let i = 0; i < preSetButtons.length; i++) {
            if (preSetButtons[i].text === button) {
                preSetButtons[i].isActive = true;
                if(fromDate?.Date)
                    setStartingDateValues({
                        ...startingDateValues,
                        firstEpochValue: fromDate?.Date
                    })
                    //setTimeStampButton(fromDate?.Date.toString());
                continue;
            }
            preSetButtons[i].isActive = false;
        }
        setButtons(preSetButtons);
    };
    

    preSetButtons.forEach((item) => {
        item.onClickCallback = handleButtonSelect;
    });

    return (  
          
        <div id="chart">
            <div className="flex justify-start items-center gap-10 text-[20px] text-[#BDB4F5] cursor-pointer font-inter">            
            Users Growth
            </div>            
            <div className="w-[100%] max-w-[1679px] p-6 pl-50 border rounded-[8px] pb-4 mt-2">
                <div className="flex justify-between items-center py-[15px] px-[2rem] pl-14">
                    <div className="text-[#E0E0E0] text-[15px] font-normal ">
                        <div className="gap-4 flex items-center  pb-[12px] pt-[12px]">
                            <span className="pl-[8px]">
                                <Calender />
                            </span>
                            {buttons.map((value) => {
                                return (
                                    <Button
                                        selected={value.isActive}
                                        className={`text-[15px] cursor-pointer`}
                                        text={value.text}
                                        key={value.text}
                                        onClick={() => {
                                            value.onClickCallback(value.text);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex justify-end items-center gap-4 mt-3">
                        {(
                            <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <div className="w-[155px] mt-[-11px] overflow-y-hidden overflow-x-hidden">
                                                <DatePicker
                                                    onChange={(newValue:any) => { 
                                                                                                    
                                                        changeDate(newValue, '1');
                                                    }}
                                                    className="relative bg-[white] text-[white] p-[5.5px] pl-[8px] rounded-[6px] text-left w-[120px] text-[14px] font-inter font-normal "
                                                    format="DD-MMM-YYYY"
                                                    disableFuture
                                                    views={['year', 'month', 'day']}
                                                    value={startingDateValues.firstDateValue}
                                                />                                    
                                        </div>
                                    </DemoContainer>
                                </LocalizationProvider>
                                <span className="text-[14px] font-medium text-[#C5C5C5] font-inter ">to</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <div className="w-[155px] mt-[-11px] overflow-y-hidden overflow-x-hidden ">
                                            <DatePicker
                                                onChange={(newValue:any) => {
                                                    changeDate(newValue, '2');
                                                }}
                                                className="relative border border-[#2B2B45] bg-[white] text-[white] p-[5.5px] pl-[8px] rounded-[6px] text-left w-[120px] font-inter text-[14px] font-normal "
                                                format="DD-MMM-YYYY"
                                                disableFuture
                                                views={['year', 'month', 'day']}
                                                value={startingDateValues.secondDateValue}
                                            />
                                        </div>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </>
                        )}  
                    </div>
                </div>
                 
                <div className="mt-3 pr-50 p-6">
                    <>
                        <ReactApexChart options={stateCustomDaily.options} series={seriesCustomDaily} type="line" height={550} width={1200} />
                    </>                 
                </div>           
            </div>
        </div>
        
    );
};