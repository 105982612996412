export const truncateToTwoDecimalPlaces = (num: number, decimals = 2) => {
    if (num || num === 0) {
        const numString = num.toString();
        const numArr = numString.split('.');

        if (numArr.length === 1) {
            return `${numArr[0]}.${'0'.repeat(decimals)}`;
        }

        if (numArr[1].length === 1) {
            return `${numArr[0]}.${numArr[1]}${'0'.repeat(decimals - 1)}`;
        }

        if (numArr[1].length >= decimals) {
            return `${numArr[0]}.${numArr[1].substring(0, decimals)}`;
        }

        return `${numArr[0]}.${numArr[1]}${'0'.repeat(decimals - numArr[1].length)}`;
    }

    return '0.00';
};
