
    // Function to fetch ETH price in USD
   export async function fetchEthPrice() {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
        );

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        return (data.ethereum.usd);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }