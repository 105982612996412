import { createContext, useContext, useEffect, useState } from 'react';

/// Screen Context to propogate width changes into the tree
const ScreenHeightContext = createContext<number>(window.innerHeight);

/// Functional Component to ensure only one listener is required to listen for width changes and can be
/// broadcasted to the entire app
export const ScreenHeightProvider = (props: any) => {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const updateScreenHeight = () => {
        setScreenHeight(window.innerHeight);
    };

    useEffect(() => {
        // Add an event listener to update screenWidth when the window is resized
        window.addEventListener('resize', updateScreenHeight);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenHeight);
        };
    }, [window.innerHeight]);

    return <ScreenHeightContext.Provider value={screenHeight}>{props.children}</ScreenHeightContext.Provider>;
};

// Helper Function to get Context in any component
export const useScreenHeightContext = () => {
    return useContext(ScreenHeightContext);
};

export default ScreenHeightProvider;
