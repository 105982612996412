import axios, { CreateAxiosDefaults } from 'axios';
import { API_RESOURCES, BASE_API_URL } from 'config/constants';
import { prependForwardSlash } from 'utils';

const baseAxiosConfig: CreateAxiosDefaults<any> = {
    // timeout: 5000
};


export const referralSystemApi = axios.create({
    baseURL: `${prependForwardSlash(BASE_API_URL)}${prependForwardSlash(API_RESOURCES.REFERRAL)}`,
    ...baseAxiosConfig
});