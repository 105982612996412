// eslint-disable-next-line
import React from 'react';
import { useBalance, useAccount, useContractRead } from 'wagmi';
import { ERC20 } from 'assets/abi';
import { ProtocolVault } from 'assets/abi';
import { CHAIN_ID, CONTRACT_ADDRESSES, TOKENS_ADDRESSES } from 'config/constants';

export const GetInsuranceFundBalance = () => {
    const INSURANCE_FUND_CONTRACT_ADDRESS = CONTRACT_ADDRESSES[CHAIN_ID].InsuranceFund;
    const TOKEN_CONTRACT_ADDRESS = TOKENS_ADDRESSES[CHAIN_ID].WETH;
    const { data: insuranceFundBalance, refetch: balanceIFRefetch } = useContractRead({
        watch: false,
        address: TOKEN_CONTRACT_ADDRESS as `0x${string}`,
        functionName: 'balanceOf',
        abi: ERC20,
        args: [INSURANCE_FUND_CONTRACT_ADDRESS],
        staleTime: 50_000,
        cacheTime: 100_000
    });
    //console.log("insuranceFundBalance , balancePVRefetch", insuranceFundBalance, balanceIFRefetch);
    return { insuranceFundBalance, balanceIFRefetch };
};

export const GetProtocolVaultFundBalance = () => {
    const TOKEN_CONTRACT_ADDRESS = TOKENS_ADDRESSES[CHAIN_ID].WETH;
    const { data: protocolVaultData, refetch: balancePVRefetch } = useContractRead({
        watch: false,
        address: TOKEN_CONTRACT_ADDRESS as `0x${string}`,
        functionName: 'balanceOf',
        abi: ERC20,
        args: [CONTRACT_ADDRESSES[CHAIN_ID].ProtocolVault],
        staleTime: 50_000,
        cacheTime: 100_000
    });
    //console.log("protocolVaultData , balancePVRefetch", protocolVaultData, balancePVRefetch);
    return { protocolVaultData, balancePVRefetch };
};

