type ToastPosition = {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
};

export const ToastPositionEnum: Record<string, ToastPosition> = {
    topLeft: {
        vertical: 'top',
        horizontal: 'left'
    },
    bottomLeft: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    topRight: {
        vertical: 'top',
        horizontal: 'right'
    },
    bottomRight: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    topCenter: {
        vertical: 'top',
        horizontal: 'center'
    },
    bottomCenter: {
        vertical: 'bottom',
        horizontal: 'center'
    },
};
