
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
function interFormat(num: any, decimalPlaces: number = 4) {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    }).format(num);
}

export default interFormat;
