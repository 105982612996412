/* eslint-disable no-unused-vars */
import { ButtonColorScheme, ButtonSize, ButtonVariants } from './definitions';

// linear(to-r, #5c03bc 12.56%, #e536ab 94.32%)

export const variantStyle: {
    [key in ButtonVariants]: {
        [key in ButtonColorScheme]: string;
    };
} = {
    default: {
        primary: `
      theme-button-color
      text-white
    `,
        success: `
      bg-green-600
      text-white
    `,
        error: `
      bg-red-600
      text-white
    `
    },
    outlined: {
        primary: 'theme-outlined',
        success: '',
        error: ''
    },
    colouredDisable: {
        primary: `
      theme-button-color
      text-white
    `,
        success: `
      bg-green-600
      text-white
    `,
        error: `
      bg-red-600
      text-white
    `
    },
    none: {
        primary: '',
        success: '',
        error: ''
    }
};

export const sizeStyle: { [key in ButtonSize]: string } = {
    sm: 'px-[1rem] py-[0.25rem] text-sm',
    md: 'px-[1.25rem] py-[0.375rem] text-base',
    lg: 'px-[1.5rem] py-[0.5rem] text-lg',
    xl: 'px-[1.75rem] py-[0.75rem] text-xl'
};

export const buttonIconSizeStyle: { [key in ButtonSize]: string } = {
    sm: '!p-2.5 text-sm',
    md: '!p-3 text-base',
    lg: '!p-3.5 text-lg',
    xl: '!p-4 text-xl'
};

export const loaderSizeStyle: { [key in ButtonSize]: number } = {
    sm: 8,
    md: 10,
    lg: 12,
    xl: 14
};
