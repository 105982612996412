// import { CSSProperties, useMemo } from 'react';
import { DetailsWithTooltip } from 'components/Common';
import { shortFormat, useWindowSize } from 'utils';
import { ReactComponent as MakerFees } from 'assets/svg/MakerFees.svg';
import { ReactComponent as TakerFees } from 'assets/svg/TakerFees.svg';
import { ReactComponent as LiquidationFee } from 'assets/svg/LiquidationFee.svg';
import { ReactComponent as TotalEarn } from 'assets/svg/TotalEarnings.svg';
import { ReactComponent as User } from 'assets/svg/User.svg';
//import { ReactComponent as NotionalValLong } from 'assets/svg/NotionalValLong.svg';

import { useEffect, useState, useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { formatUnits } from 'utils/formatUnits';
import dayjs from 'dayjs';
import { ButtonInterface } from '../../interfaces/DatePage';
import { GetInsuranceFundBalance, GetProtocolVaultFundBalance } from './GetBalance';

  const GET_PROTOCOL_STATS = gql`query {
    protocolStats {
      totalMakerFeeGenerated
      totalTakerFeeGenerated
      totalLiquidationFeeGenerated
    }
  }`
const preSetButtons: Array<ButtonInterface> = [
    {
        text: '1D',
        isActive: true
    },
    {
        text: '7D',
        isActive: false
    },
    {
        text: '1M',
        isActive: false
    },
    {
        text: '3M',
        isActive: false
    },
    {
        text: '6M',
        isActive: false
    }
];

const Details = () => {
    const [buttons, setButtons] = useState<Array<ButtonInterface>>(preSetButtons);
    const [timestampVal, setTimeStampButton] = useState('');
    const date = dayjs().subtract(6, 'months');
    const [totalFee, setNetFees] = useState(0);
    const [makerFee, setMakerFee] = useState(0);
    const [takerFee, setTakerFee] = useState(0);
    const [liqFee, setLiquidationFee] = useState(0);
    //const ALL_TIME_TIMESTAMP = "43084000";

    const { loading:loadingfees, data:netfees} = useQuery(GET_PROTOCOL_STATS, {
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if(!loadingfees) {
            const totalfees = netfees.protocolStats[0].totalMakerFeeGenerated +
                            netfees.protocolStats[0].totalTakerFeeGenerated +
                            netfees.protocolStats[0].totalLiquidationFeeGenerated;
            setNetFees(totalfees);  
            setMakerFee(netfees.protocolStats[0].totalMakerFeeGenerated);
            setTakerFee(netfees.protocolStats[0].totalTakerFeeGenerated);
            setLiquidationFee(netfees.protocolStats[0].totalLiquidationFeeGenerated);
            //console.log("protocol stats",netfees);            
        }
    }, [loadingfees, totalFee]);  
    const insuranceBal:any = GetInsuranceFundBalance();
    const protocolVaultBal:any = GetProtocolVaultFundBalance();
    //console.log("insuranceBal,protocolVaultBal",insuranceBal,protocolVaultBal);
    //const netNotionalLongVal:any = netLongAmt * (markprice?.markPrices[0].MarkPrice) * ethPrice;//0;//isNaN(Number(netLongAmt)) ? 0 : (netLongAmt*markprice*ethPrice);
    //const netNotionalShortVal:any = 0;//isNaN(Number(netShortAmt)) ? 0 : (netShortAmt*markprice*ethPrice);
    
    return (    
        <div className="w-[100%] max-w-[1679px] p-6 pl-50 theme-gradient rounded-[8px] pb-4">
            <div className=" text-[18px] font-inter text-white">Protocol Statistics</div>
            <div className="w-full h-[1px] bg-[#ffffff75] opacity-80 my-4" />
            <div className="text-[#E0E0E0] text-[15px] font-normal ">
                        
                    </div>
            <div className="grid grid-cols-6 4xl:grid-cols-6 gap-4">
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <MakerFees/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {shortFormat(formatUnits(makerFee.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Maker Fees                          
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Long positions"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <TakerFees/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {shortFormat(formatUnits(takerFee.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Taker Fees                        
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Short positions"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <LiquidationFee/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {shortFormat(formatUnits(liqFee.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Liquidation Earnings                      
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Number of new open positions"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <TotalEarn/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {shortFormat(formatUnits(totalFee.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Total Earnings                        
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Taker Fees"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <User/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {shortFormat(formatUnits(protocolVaultBal?.protocolVaultData?.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Protocol Vault
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"User"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[18px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <User/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {shortFormat(formatUnits(insuranceBal?.insuranceFundBalance?.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Insurance Funds
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Taker Fees"} />
                        </span>
                    </div>
                    
               </div>
               
            </div>
        </div>
    );
};
const DashboardProtocolPage = () => {
    const maxWidth = 679;
    const { windowInnerWidth } = useWindowSize();
    return (
        <div>
            {!windowInnerWidth ? null : windowInnerWidth >= maxWidth ? (
                <div className="flex gap-4 p-[1rem] flex-col">
                    <div className="flex  w-full gap-y-2">
                        <Details />
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 p-[1rem]">
                    {/* <LeaderboardPage />                     */}
                </div>
            )}
        </div>
    );
};

export default DashboardProtocolPage;
