export enum MessageHeader {
    LEVERAGE = 'Leverage',
    TAKER_ORDER = 'Taker Order',
    MAKER_ORDER = 'Maker Order',
    APPROVE = 'Approve',
    DEPOSIT = 'Deposit',
    WITHDRAW = 'Withdraw',
    OPEN_ORDER = 'Open Order',
    CLAIM_FUNDING = 'Claim Funding',
    CLOSE_POSITION = 'Close Position',
    MINT_WETH = 'Mint',
    CLAIM_PRE_SALE = 'Claim Tokens',
    DEFAULT = 'Default'
}

const USER_REJECTED_MESSAGE = `Whoa! You declined to sign the transaction. Please try again when you're ready`;

const messageDescriptions: {
    [header in MessageHeader]: { errorMessage: string; successMessage: string };
} = {
    Leverage: {
        errorMessage: 'Oops! A minor glitch occurred. Please try again',
        successMessage: 'Hooray! Transaction successfully executed with Leverage!'
    },
    'Maker Order': {
        errorMessage: `Oh no! Something didn't quite work. Give it another shot!`,
        successMessage: 'Nice move! Your Limit Order is now placed.'
    },
    'Taker Order': {
        errorMessage: `Uh-oh! Hiccup placing your Market Order. Please try again!`,
        successMessage: 'Boom! Your Market Order is now live and kicking'
    },
    'Open Order': {
        errorMessage: 'Oopsie daisy! Removing your Open Order hit a snag. Give it another go',
        successMessage: 'Way to go! Your Open Order has been successfully removed'
    },
    'Claim Funding': {
        errorMessage: 'Yikes! Something went haywire. Take a breath and try again',
        successMessage: `Bravo! Funding successfully claimed. You're rocking it!`
    },
    Approve: {
        errorMessage: 'Oops! Approval of Allowance hit a little snag. Try again',
        successMessage: `High five! Allowance approved! Proceed to deposit now.`
    },
    Deposit: {
        errorMessage: `Uh-oh! Depositing didn't quite go as planned. Take another shot!`,
        successMessage: `Woo-hoo! You've successfully made a deposit. Great job!`
    },
    Withdraw: {
        errorMessage: 'Oh snap! Withdrawing could have gone better. Try again',
        successMessage: `Voila! Withdrawal successfully completed. You're killing it!`
    },
    'Close Position': {
        errorMessage: `Oops! Closing your Position hit a little snag. Try one more time!`,
        successMessage: `Congrats! You've successfully closed your position. Awesome move!`
    },
    Mint: {
        errorMessage: `Uh-oh! Minting encountered a hiccup. Retry, please`,
        successMessage: `Fantastic! Minting successfully completed. You're on a roll!`
    },
    'Claim Tokens': {
        errorMessage: `Oops! Try one more time!`,
        successMessage: `Congrats! You've successfully claimed your tokens. Awesome move!`
    },
    Default: {
        errorMessage: `Whoops! Something unexpected happened. Try it again`,
        successMessage: `Hooray! Congrats on the success. You're a star!`
    }
};

export default function getNotificationMessages(header: MessageHeader) {
    for (const [key, value] of Object.entries(messageDescriptions)) {
        if (key === header) return { ...value, userRejected: USER_REJECTED_MESSAGE };
    }
    return { ...messageDescriptions.Default, userRejected: USER_REJECTED_MESSAGE };
}
