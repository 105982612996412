import { truncateToTwoDecimalPlaces } from './truncateValue';

export function shortFormat(num: any, decimals = 2) {
    const number = Number(num);
    // Check if the number is negative
    const isNegative = number < 0;

    // Convert to positive if negative
    const positiveNumber = isNegative ? -number : number;

    if (positiveNumber >= 1000000000000000000000) {
        return (
            (isNegative ? '-' : '') +
            truncateToTwoDecimalPlaces(positiveNumber / 1000000000000000000000, decimals) +
            'Sx'
        );
    }

    if (positiveNumber >= 1000000000000000000 && positiveNumber < 1000000000000000000000) {
        return (
            (isNegative ? '-' : '') +
            truncateToTwoDecimalPlaces(positiveNumber / 1000000000000000000, decimals) +
            'Qi'
        );
    }

    if (positiveNumber >= 1000000000000000 && positiveNumber < 1000000000000000000) {
        return (
            (isNegative ? '-' : '') +
            truncateToTwoDecimalPlaces(positiveNumber / 1000000000000000, decimals) +
            'Qa'
        );
    }

    if (positiveNumber >= 1000000000000 && positiveNumber < 1000000000000000) {
        return (
            (isNegative ? '-' : '') +
            truncateToTwoDecimalPlaces(positiveNumber / 1000000000000, decimals) +
            'T'
        );
    }

    if (positiveNumber >= 1000000000 && positiveNumber < 1000000000000) {
        return (
            (isNegative ? '-' : '') + truncateToTwoDecimalPlaces(positiveNumber / 1000000000, decimals) + 'B'
        );
    }

    if (positiveNumber >= 1000000 && positiveNumber < 1000000000) {
        return (isNegative ? '-' : '') + truncateToTwoDecimalPlaces(positiveNumber / 1000000, decimals) + 'M';
    }

    if (positiveNumber >= 1000 && positiveNumber < 1000000) {
        return (isNegative ? '-' : '') + truncateToTwoDecimalPlaces(positiveNumber / 1000, decimals) + 'K';
    }

    return (isNegative ? '-' : '') + truncateToTwoDecimalPlaces(positiveNumber, decimals);
}

export default shortFormat;
