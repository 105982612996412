import EthIcon from 'components/Icons/EthIcon';
import React, { useEffect, useState } from 'react';

interface LabelWethIconProps {
    height: number;
}

const LabelWethIcon = ({ height }: LabelWethIconProps) => {
    const [width, setWidth] = useState(7);

    useEffect(() => {
        setWidth((height * 3) / 5);
    }, [height]);

    return (
        <span className="pl-[6px] flex items-center">
            <EthIcon height={height} width={width} />
        </span>
    );
};

export default LabelWethIcon;
