import dayjs from 'dayjs';

export function getTime(offset: 'ALL' | '1D' | '7D' | '1M' | '3M' | '6M') {
    let date;
    switch (offset) {
        case '1D': {
            //console.log("1D printing");
            date = dayjs().subtract(1, 'day');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        case '7D': {
            date = dayjs().subtract(7, 'days');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        case '1M': {
            date = dayjs().subtract(1, 'month');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        case '3M': {
            date = dayjs().subtract(3, 'months');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        case '6M': {
            date = dayjs().subtract(6, 'months');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
    }
}
