export const timeSort = (a: any, b: any, orderBy: boolean) => {
    const aTimeStamp =
        a.__typename === 'MakerOrder'
            ? Number(a?.transaction[0]?.timestamp)
            : Number(a?.transaction?.timestamp);
    const bTimeStamp =
        b.__typename === 'MakerOrder'
            ? Number(b?.transaction[0]?.timestamp)
            : Number(b?.transaction?.timestamp);
    if (orderBy) {
        return bTimeStamp - aTimeStamp;
    } else {
        return aTimeStamp - bTimeStamp;
    }
};

export const sizeSort = (a: any, b: any, type: 'ASC' | 'DESC' | 'NONE', 
field: 'points' | 'rpnl' | 'fee' | 'trades' | 'upnl' | 'roi' | 'lvol' | 'svol' | 'none') => {    
    if (type === 'NONE') return;
    if (field === 'points')
        return type === 'ASC' ? Number(a?.points) - Number(b?.points) : Number(b?.points) - Number(a?.points);
    else if (field === 'rpnl')
        return type === 'ASC' ? Number(a?.price) - Number(b?.price) : Number(b?.price) - Number(a?.price);
    else if (field === 'fee')
        return type === 'ASC' ? Number(a?.fee) - Number(b?.fee) : Number(b?.fee) - Number(a?.fee);
    else if (field === 'trades')
        return type === 'ASC' ? Number(a?.trades) - Number(b?.trades) : Number(b?.trades) - Number(a?.trades);
    else if (field === 'upnl')
        return type === 'ASC' ? Number(a?.unrealizedpnl) - Number(b?.unrealizedpnl) : Number(b?.unrealizedpnl) - Number(a?.unrealizedpnl);
    else if (field === 'roi')
        return type === 'ASC' ? Number(a?.roi) - Number(b?.roi) : Number(b?.roi) - Number(a?.roi);
    else if (field === 'lvol')
        return type === 'ASC' ? Number(a?.tradelongvol) - Number(b?.tradelongvol) : Number(b?.tradelongvol) - Number(a?.tradelongvol);
    else if (field === 'svol')
        return type === 'ASC' ? Number(a?.tradeshortvol) - Number(b?.tradeshortvol) : Number(b?.tradeshortvol) - Number(a?.tradeshortvol);
    else return;
};
