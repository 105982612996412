interface ShareBoxIconSvgProps {
    color?: string;
}

const ShareBoxIconSVG = ({ color = '#D8BAFF' }: ShareBoxIconSvgProps) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 7.66569V11.6657C11 12.0193 10.8595 12.3585 10.6095 12.6085C10.3594 12.8585 10.0203 12.999 9.66667 12.999H2.33333C1.97971 12.999 1.64057 12.8585 1.39052 12.6085C1.14048 12.3585 1 12.0193 1 11.6657V4.33236C1 3.97873 1.14048 3.6396 1.39052 3.38955C1.64057 3.1395 1.97971 2.99902 2.33333 2.99902H6.33333"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M8.99902 1H12.999V5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.6665 8.33333L12.9998 1" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default ShareBoxIconSVG;
