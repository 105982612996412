import LoadingSkeleton from 'components/Animation/LoadingSkeleton';
import LabelWethIcon from 'components/Common/Units/LabelWethIcon';
import LabelSnvIcon from 'components/Common/Units/LabelSnvIcon';
import { ReactComponent as Starreward } from 'assets/svg/Starreward.svg';
import { IoCopyOutline } from "react-icons/io5";
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect } from 'react';
import { referralSystemApi } from 'config/axios';
import { useNavigate } from 'react-router-dom';
import { useReferrerAddress } from 'store';
import ShareBoxIconSVG from 'assets/svg/ShareBoxIconSVG';

interface LeaderboardTableRowProps {
    item: any;
    loading: boolean;
}

const LeaderboardTableRow = ({ item, loading }: LeaderboardTableRowProps) => {
    const [tooltipTitle, setTooltipTitle] = useState('Copy address');
    const checkYourRewards = 'Click to check your rewards';
    const [usersCount, setUsersCount] = useState(0);
    const navigate = useNavigate();
    const {setReferrerAddress} = useReferrerAddress();
    //console.log("Leaderboard row item",item);
    const handleCopyAddress = (useraddr:any) => {        
        navigator.clipboard.writeText(useraddr);  
        setTooltipTitle('Copied');
        setTimeout(() => setTooltipTitle('Copy address'), 2000); // Reset after 2 seconds
      };
      
      useEffect(() => {
        const fetchUsersCount = async () => {
          try {
            const response = await referralSystemApi.get(`/getReferredUsersCount`, {
              params: {
                referrerAddr: item.useraddr,
              },
            });            
            setUsersCount(response.data.usersCount);
          } catch (error) {
            //setError(error.message);
          }
        };
    
        fetchUsersCount();
      }, [item.useraddr]);

      const handleRewardsUsersClick = (userAddr:any) => {
        
        //const user = item.useraddr;
        setReferrerAddress(userAddr);
        navigate('/dashboard/rewards', { state: { user:userAddr } });
      };
      
    return (
        
        <>       
            <div className="grid grid-cols-12 gap-6 px-[10px] items-center bg-[#10141B] rounded-md mb-[8px] h-[32px] ">
                <div className="text-[1.125rem] font-normal font-inter text-white text-left ">
                    {loading ? <LoadingSkeleton /> : item.id}
                </div>
                <div className="flex justify-start items-center w-[105px] mx-auto">
                    
                    <span className="flex justify-end items-center">
                        <Starreward height={14} />
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.points}
                        </span>
                    </span>                    
                </div>
                <div
                    className={`flex justify-start items-center text-center text-white  text-[1.125rem] font-normal`}                    
                >
                    <div className="flex items-center">
                        <span className="pr-[8px] w-24">
                            {loading ? (
                                <LoadingSkeleton className="w-full" />
                            ) : item.useraddr.slice(0,4)+"..."+item.useraddr.slice(-4)}
                        </span>
                        <Tooltip title={tooltipTitle} placement="top-start">
                            <span className= "cursor-pointer" onClick={() => handleCopyAddress(item.useraddr)}>
                                <IoCopyOutline />
                            </span>
                        </Tooltip>
                    </div>
                                                                             
                </div>
                <div className="flex">
                    <span className="pr-[1px] w-12 flex justify-left ">
                        {loading ? (
                        <LoadingSkeleton className="w-full" />
                        ) : (usersCount) ? usersCount : 0}
                    </span>                        
                    <Tooltip title={checkYourRewards} placement="top-start">
                        <span className="cursor-pointer  flex justify-center mt-[8px]" onClick=
                        {() => (usersCount>0) ?handleRewardsUsersClick(item.useraddr):""}>
                        <ShareBoxIconSVG />
                        </span>
                    </Tooltip>
                </div>
                
                <div className="flex justify-start items-center">
                    <span className="flex justify-end items-center text-end ">
                        <span className="text-[16px] font-normal text-white product-name ">
                            {loading ? (
                                <LoadingSkeleton className="ml-[-25px]" />
                            ) : (
                                item.roi+"%"
                            )}
                        </span>
                    </span>
                </div>
                <div className="flex justify-start items-center w-[105px] mx-auto">
                    <span className="flex justify-end items-center">
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.fee}
                        </span>
                    </span>
                    <LabelWethIcon height={14} />
                </div>
                <div className="flex justify-start items-center w-[105px] mx-auto">
                    <span className="flex justify-end items-center">
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.trades}
                        </span>
                    </span>
                </div>
                <div className="flex justify-start items-center w-[105px] mx-auto">
                    <span className="flex justify-end items-center">
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.price}
                        </span>
                    </span>
                    <LabelWethIcon height={14} />
                </div>
                <div className="flex items-center w-[105px] mx-auto">
                    <span className="flex items-center">
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.unrealizedpnl}
                        </span>
                    </span>
                    <LabelWethIcon height={14} />
                </div>
                <div className="flex justify-start items-center w-[105px] mx-auto">
                    <span className="flex justify-end items-center">
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.deposit}
                        </span>
                    </span>
                    <LabelWethIcon height={14} />
                </div>
                <div className="flex justify-start items-center w-[105px] mx-auto">
                    <span className="flex justify-end items-center">
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.tradelongvol}
                        </span>
                    </span>
                    <LabelSnvIcon />
                </div>
                <div className="flex justify-start items-center w-[105px] mx-auto">
                    <span className="flex justify-end items-center">
                        <span className="text-[16px] font-normal text-white product-name">
                            {loading ? <LoadingSkeleton /> : item.tradeshortvol}
                        </span>
                    </span>
                    <LabelSnvIcon />
                </div>
            </div>
        </>
    );
};
export default LeaderboardTableRow;
