import { WeeklyUsers } from './UsersGrowth/WeeklyUsers';
import { useWindowSize } from 'utils';

const DashboardMarketingPage = () => {
    const maxWidth = 679;
    const { windowInnerWidth } = useWindowSize();
    return (
        <div>
            {!windowInnerWidth ? null : windowInnerWidth >= maxWidth ? (
                <div className="flex gap-4 p-[1rem] flex-col">
                    <div className="flex  w-full gap-y-2">
                        <WeeklyUsers />
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 p-[1rem]">
                    {}
                </div>
            )}
        </div>
    );
};

export default DashboardMarketingPage;
