import NotificationProvider from './NotificationContext/NotificationContext';
import ScreenHeightProvider from './ScreenContext/ScreenHeightContext';
import ScreenWidthProvider from './ScreenContext/ScreenWidthContext';

const CustomContextProvider = (props: any) => {
    return (       
            <ScreenHeightProvider>
                <ScreenWidthProvider>
                        <NotificationProvider>                               
                            {props.children}
                        </NotificationProvider>
                </ScreenWidthProvider>
            </ScreenHeightProvider>
    );
};

export default CustomContextProvider;
