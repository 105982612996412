import React from 'react';

const LabelSnvIcon = () => {
    return (
        <span className="text-[10px] font-normal font-inter h-[16px] bg-[#1C212C] text-[#E9E9E9] px-[8.85px] py-[1px] rounded-sm">
            SNV
        </span>
    );
};

export default LabelSnvIcon;
