import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as BetaLogo } from 'assets/svg/beta0logo.svg';
import { ReactComponent as TestLogo } from 'assets/svg/test0logo.svg';
import { Dropdown, Menu } from 'antd';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { IS_MAINNET } from 'config/constants';

const NavbarLeft = () => {
    const [selectedKey, setSelectedKey] = useState(false);
    const selectedChain = /*IS_MAINNET ? 'Polygon Mainnet' : */'Base Testnet';
    const handleMenuClick = (key: any) => {
        setSelectedKey(key === 0 ? false : true);
    };

    return (
        <div className="flex items-center h-8">
            <div className="flex gap-4 px-3 border-r mr-2 border-blivBorderSm-950 relative right-[10px] ">
                <div className="flex items-center gap-[4px] justify-center cursor-pointer ">
                    <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        overlay={
                            <Menu
                                selectedKeys={[selectedChain]}
                                onClick={({ key }) => handleMenuClick(key.toString())}
                                style={{ backgroundColor: '#010202' }}
                                className="text-[14px] font-normal text-white font-inter w-[198px] relative border border-[#31324B] px-5 top-[15px]"
                            >
                                {selectedKey === false ? (
                                    <Menu.Item
                                        key="1"
                                        style={{
                                            backgroundColor: '#010202',
                                            color: '#AEAFC1',
                                            border: 'none'
                                        }}
                                    >
                                        <a href="https://perp.nftfn.xyz/trade">
                                            <div className="flex items-center">
                                                <span>
                                                    <BetaLogo />
                                                </span>
                                                <span>Base Mainnet</span>
                                            </div>
                                        </a>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item
                                        key="0"
                                        style={{
                                            backgroundColor: '#010202',
                                            color: '#AEAFC1',
                                            border: 'none'
                                        }}
                                    >
                                        <a href="https://test.nftfn.xyz">
                                            <div className="flex items-center">
                                                <span>
                                                    <TestLogo />
                                                </span>
                                                <span>Base Testnet</span>
                                            </div>
                                        </a>
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                    >
                        <div className="flex justify-center items-center">
                            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                {selectedKey === false ? (
                                    <div className="flex justify-between items-center">
                                        <span>
                                            <TestLogo />
                                        </span>
                                        <span>Base Testnet</span>
                                    </div>
                                ) : (
                                    <div className="flex justify-between items-center">
                                        <span>
                                            <BetaLogo />
                                        </span>
                                        <span>Base Mainnet</span>
                                    </div>
                                )}
                            </a>
                            <span>
                                <RiArrowDropDownFill style={{ height: '30px', width: '30px' }} />
                            </span>
                        </div>
                    </Dropdown>
                </div>
            </div>           
        </div>
    );
};

export default NavbarLeft;
