import React from 'react';

interface EthIconProps {
    height: number;
    width: number;
}

const EthIcon = ({ height, width }: EthIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.9113 0L2.84766 0.216171V6.4884L2.9113 6.55189L5.82274 4.83091L2.9113 0Z"
                fill="white"
            />
            <path d="M2.91151 0L0 4.83091L2.91151 6.55189V3.50752V0Z" fill="white" />
            <path
                d="M2.91087 7.10314L2.875 7.14688V9.38114L2.91087 9.48585L5.82409 5.38306L2.91087 7.10314Z"
                fill="white"
            />
            <path d="M2.91151 9.48585V7.10314L0 5.38306L2.91151 9.48585Z" fill="white" />
            <path d="M2.91113 6.55187L5.82257 4.8309L2.91113 3.50751V6.55187Z" fill="white" />
            <path d="M0 4.8309L2.91151 6.55187V3.50751L0 4.8309Z" fill="white" />
        </svg>
    );
};

export default EthIcon;
