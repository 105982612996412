import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface LoadingSkeleton {
    className?: string;
    widthValue?: number;
} 

const LoadingSkeleton: React.FC<LoadingSkeleton> = ({ className, widthValue }) => {
    return (
        <div className={className} >
            <Skeleton
                baseColor="#ffffff40"
                highlightColor="transparent"
                count={1}
                width={widthValue ? `${widthValue}px` : "50px"}
            />
        </div>
    );
};

export default LoadingSkeleton;


