 import { shortFormat, formatUnits, useWindowSize } from 'utils';
import { useEffect, useState, useMemo } from 'react';
import { referralSystemApi } from 'config/axios';
import { PiArrowRightLight } from "react-icons/pi";  
import { useReferrerAddress } from 'store';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/client';
import { DetailsWithTooltip } from 'components/Common';
import { truncateToTwoDecimalPlaces } from 'utils/truncateValue';

interface User {
    userAddr: string;
  }
interface UserFee {
    address: string;
    fee: string;
  }
const Details = () => {
    const client = useApolloClient();
    const [usersList, setUsersList] = useState<User[]>([]);
    const {refAddr} = useReferrerAddress();
    const [userFees, setUserFees] = useState<UserFee[]>([]);
    const [totalFee, setTotalFee] = useState(0);            
    const FEE_DETAILS = gql`query UserAccountDetails($address: ID!) {
            userAccount(id: $address) {
                cumulativeFees
            }
        }`;
        
    useEffect(() => {
        const fetchRefUsersList = async () => {
          //console.log("refAddr",refAddr);
          try {
            const response = await referralSystemApi.get(`/getReferredUsersList`, {
              params: {
                referrerAddr: refAddr,//item.useraddr,
              },
            }); 
            //console.log("response from users List",response);
            setUsersList(response.data.usersList);
          } catch (error) {
            //setError(error.message);
          }
        };
    
        fetchRefUsersList();
      }, [refAddr]);
    
    const getFeeForUser = (address: string) => {
        const feeDetail:any = userFees.find(fee => fee?.address == address);
        return feeDetail ? feeDetail.fee : null;
    };
    
    useEffect(() => {
        const fetchUserFees = async () => {
            
                try {
                    //console.log("usersList",usersList);
                    const promises = usersList.map(user => {
                        return client.query({
                          query: FEE_DETAILS,
                          variables: { address: user.userAddr },
                        }).then(response => {
                            const formatFee:any = shortFormat(formatUnits((response.data.userAccount?.cumulativeFees)));                            
                            return { address: user.userAddr, fee: Number(formatFee)};
                        });
                      });
                      
                      
                    const results:any = await Promise.all(promises);
                    const total = results.reduce((acc:any, result:any) => acc + (0.25 * result.fee), 0);
                    setTotalFee(total);
                    setUserFees(results);
                } catch (err) {
                  //setError(err);
                }
              };
          
        fetchUserFees();
        }, [usersList]);   
 
     return (  
        <div id="chart">
            <div className="flex justify-start items-center gap-10 text-[20px] text-[#BDB4F5] cursor-pointer font-inter">            
            Rewards
            </div>             
            <div className="w-[100%] max-w-[1679px] p-6 pl-50 border rounded-[8px] pb-4 mt-2">
                <div className="flex justify-between items-center py-[15px] px-[2rem] max-w-[1679px] mx-auto ">                    
                    <div className="p-6 pt-[14px] w-[231px] h-[76px] bg-[#10141B] text-[14px] rounded-[10px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                        <div className="text-white text-[20px] font-bold mb-[6px]">
                            {refAddr.slice(0,4)+"..."+refAddr.slice(-4)}
                        </div>
                        User Address
                    </div>
                    <div className="flex flex-col items-center">
                        {usersList.map(user => (
                            <div key={user.userAddr} className="flex flex-row items-center mb-4">
                                <div className="flex items-center mt-[2px]">
                                    <PiArrowRightLight style={{ height: '50px', width: '100px', color: '#FFFFFF66' }} />
                                    <div className="p-6 pt-[14px] w-[231px] h-[76px] theme-gradient font-inter text-[14px] rounded-[8px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                    <div className="text-white text-[20px] font-inter mb-[6px]">
                                        {user.userAddr.slice(0,4)+"..."+user.userAddr.slice(-4)}
                                    </div>
                                    Referred User 
                                    </div>
                                </div>
                            <div className="flex items-center mb-1 ml-4">
                                <PiArrowRightLight style={{ height: '50px', width: '100px', color: '#FFFFFF66' }} />
                                <div className="ml-4">
                                    <div className="p-6 pt-[14px] w-[231px] h-[76px] theme-gradient rounded-[8px] font-inter text-[14px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="text-white text-[20px] font-bold mb-[6px]">
                                        {getFeeForUser(user.userAddr) + " WETH"}
                                        </div>
                                        <div className="flex items-center">
                                            Fee
                                            <span className='ml-2 mt-1'>
                                                <DetailsWithTooltip tooltipText={"Total fee generated on the NFTFN platform"} />
                                            </span>                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="flex items-center mb-1 ml-4">
                                <PiArrowRightLight style={{ height: '50px', width: '100px', color: '#FFFFFF66' }} />
                                <div className="ml-4">
                                <div className="p-6 pt-[14px] w-[231px] h-[76px] theme-gradient rounded-[8px] font-inter text-[14px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                    <div className="text-white text-[20px] font-bold mb-[6px]">
                                    {truncateToTwoDecimalPlaces(0.25 * getFeeForUser(user.userAddr))+ " WETH"}
                                    </div>
                                    <div className="flex items-center">
                                        Your Share
                                        <span className='ml-2 mt-1'>
                                            <DetailsWithTooltip 
                                            tooltipText={"Your share is 25% of the fee"}
                                           />
                                        </span>                                            
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex items-center  ml-8 mb-4">
                                
                        <div className="ml-8">
                            <div className="p-6 pt-[14px] w-[231px] h-[76px] theme-gradient rounded-[8px] font-inter text-[14px] text-[#E8E8E8]" style={{ whiteSpace: 'nowrap' }}>
                                <div className="flex items-center mt-1">
                                    Total Claimable Fee 
                                    <span className='ml-2'>
                                        <DetailsWithTooltip tooltipText={"Get ready! NFTFN will airdrop these rewards directly to you"} />
                                    </span>                                            
                                </div>
                                <div className="text-white text-[20px] font-bold mb-[6px]">
                                {truncateToTwoDecimalPlaces(totalFee)+ " WETH"}
                                </div>
                                
                            </div>
                        </div>
                           
                    </div>                    
                                  
                </div>
            </div>            
        </div>
        
        
    );
};
const DashboardRewardsPage = () => {
    const maxWidth = 679;
    const { windowInnerWidth } = useWindowSize();
    return (
        <div>
            {!windowInnerWidth ? null : windowInnerWidth >= maxWidth ? (
                <div className="flex gap-4 p-[1rem] flex-col">
                    <div className="flex  w-full gap-y-2">
                        <Details />
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 p-[1rem]">
                    {/* <LeaderboardPage />                     */}
                </div>
            )}
        </div>
    );
};

export default DashboardRewardsPage;
