import gql from 'graphql-tag';

export const WALLETS = gql`query {
    protocolStats {
      totalUsers
    } 
  }`
  
  
  export const  GET_LIQUIDATEE_COUNT = gql`query {
    liquidations(first: 1000) {
      liquidatee {
        address
      }
    }
  }`
  
  export const GET_NET_LIQUIDATION_EARNINGS = gql`query {
    protocolStats {
      totalLiquidationFeeGenerated
    }
  }`
 
  
  export const WALLETS_LEFT = gql`query {
    positions(where: {status: Closed}, first: 1000) {
      user {
        address
      }
    }
  }`

  
export const GET_USER_TRADE_COUNT_VOLUME = gql`
query GetUserTradeCountVolume($timestamp: String, $first: Int!, $skip: Int!, $count: String) {
  userAccounts (where: {createdAt_gte: $timestamp} 
    first: $first
    skip: $skip) {
    id
    address
    cumulativeFees
    escrowedAmount
    createdAt
    takerOrders (where: {timestamp_gt: $timestamp} 
      first: $first) {
      id
    }
    totalPositions (where: {status: Open, startTimestamp_gt: $timestamp}) {
      amount
      realizedPnl
      averageBuyingPrice
      side
    }
  }          
}
`;
export const GET_INITIAL_INVESTMENT = gql `query {
userDeposits(first: 3, orderBy: user__createdAt, orderDirection: asc) {
  amount
}
}`

export const GET_MARKPRICE = gql`query {
markPrices(orderBy: id, orderDirection: desc, first: 1) {
  id
  MarkPrice
}
}`
  

  