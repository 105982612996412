import React, { useEffect } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { ReactComponent as SuccessTick } from 'assets/svg/successIcon.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/external-link.svg';
import { ReactComponent as ErrorTick } from 'assets/svg/crossIcon.svg';
import { useGetTxUrl } from 'utils';
import { useSnackbar } from 'notistack';
import { ToastPositionEnum } from './helper';

const { getTxUrl } = useGetTxUrl();

export interface ToastProps {
    type: string;
    messageHead: string;
    messageContent?: string;
    isTransaction: boolean;
    data: any;
}

const Toast = ({ type, messageHead, messageContent, isTransaction, data }: ToastProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        let snackbarKey: string | number | undefined;

        const showSnackbar = () => {
            snackbarKey = enqueueSnackbar(messageContent, {
                anchorOrigin: ToastPositionEnum['topRight'],
                preventDuplicate: false,
                content: () => (
                    <div
                        className={`border ${
                            type === 'Success' ? 'border-[#75B587]' : 'border-[#DB434D]'
                        } bg-[#10141B] h-fit pb-1 w-[182px] rounded-[8px] flex justify-start items-center lg:w-[365px] lg:min-w-[365px] relative right-[10%] top-[50px]`}
                        style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', cursor: 'pointer' }}
                        onMouseEnter={() => clearTimeout(timeout)}
                        onMouseLeave={() => hideSnackbar()}
                    >
                        <div className="flex justify-between h-full space-x-[18px] mt-2 pb-2 pr-2">
                        <span className="ml-[10px] mt-[8px] lg:mt-[13px]">
                            {type === 'Success' ? (
                                <SuccessTick className="h-[19px] w-[19px] lg:h-[36px] lg:w-[36px]" />
                            ) : (
                                <ErrorTick className="h-[19px] w-[19px] lg:h-[36px] lg:w-[36px]" />
                            )}
                        </span>
                        <span className="flex flex-col justify-start gap-1">
                            <span className="flex items-center gap-2 text-[12px] font-normal text-white font-inter lg:text-[20px] lg:font-medium">
                                <span>{messageHead}</span>
                                {isTransaction && (
                                    <a
                                        className="underline text-blue-500"
                                        target="_blank"
                                        href={getTxUrl(data)}
                                        rel="noreferrer"
                                    >
                                        <LinkIcon className="h-[12px] w-[12px]" />
                                    </a>
                                )}
                            </span>
                            <span className="text-[8px] font-normal text-white font-inter lg:text-[16px]">
                                {messageContent}
                            </span>
                        </span>
                        <span
                            className="fixed cursor-pointer lg:top-[145px] lg:right-[60px]"
                            onClick={() => closeSnackbar(snackbarKey)} 
                        >
                            <RxCross2
                                style={{
                                    height: '14px',
                                    width: '14px',
                                    color: 'white',
                                    marginTop: '-80px',
                                    marginLeft: '150px'
                                }}
                            />
                        </span>
                    </div>
                    </div>
                )
            });
        };

        const hideSnackbar = () => {
            if (snackbarKey) {
                closeSnackbar(snackbarKey);
            }
        };

        showSnackbar();

        const snackbarContainer = document.querySelector('.snackbar-container-class'); 
        if (snackbarContainer) {
            snackbarContainer.addEventListener('mouseenter', () => {
                clearTimeout(timeout);
            });

            snackbarContainer.addEventListener('mouseleave', () => {
                hideSnackbar();
            });
        }

        const timeout = setTimeout(() => {
            hideSnackbar();
        }, 5000);

        return () => {
            clearTimeout(timeout);
            if (snackbarContainer) {
                snackbarContainer.removeEventListener('mouseenter', () => clearTimeout(timeout));
                snackbarContainer.removeEventListener('mouseleave', () => hideSnackbar());
            }
        };
    }, [enqueueSnackbar, closeSnackbar, messageContent, data, isTransaction, type]);

    return null;
};

export default Toast;
