import { useState, useEffect } from 'react';

// Define an interface for the size object
interface Size {
    windowInnerWidth: number;
    windowInnerHeight: number;
}

const useWindowSize = (): Size => {
    const [windowSize, setWindowSize] = useState<Size>({
        windowInnerWidth: 0,
        windowInnerHeight: 0
    });

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            // Set window windowInnerWidth/height to state
            setWindowSize({
                windowInnerWidth: window.innerWidth,
                windowInnerHeight: window.innerHeight
            });
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

export default useWindowSize;
