import { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { DashboardPage } from './pages';
import Navbar from 'components/Navbar';

import { DashboardDetailsPage }  from './pages';
import { DashboardTradePage }  from './pages/DashboardTradePage';
import { DashboardProtocolPage }  from './pages/DashboardProtocolPage';
import { DashboardMarketingPage }  from './pages/DashboardMarketingPage';
import { DashboardUsersPage } from './pages/DashboardUsersPage';
import { DashboardRewardsPage } from './pages/DashboardRewardsPage';

function App() {

    const [isLiteMode, setIsLiteMode] = useState(window.matchMedia('(max-width: 1024px)').matches);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1024px)');

        const handleMediaQueryChange = (e: any) => {
            setIsLiteMode(e.matches);
        };

        mediaQuery.addListener(handleMediaQueryChange);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    

    

    return (
        <>
            {/* {isLiteMode ? (<div>
                
                </div>
            ) : ( */}
                <div>
                    
                    
                        <Navbar/>

                        
                        
                        <Routes>
                            <Route path="/" element={<Navigate to={'/dashboard'} replace />} />  
                            <Route path="dashboard/*" element={<DashboardPage />}>
                                <Route path="details" element={<DashboardDetailsPage />} />
                                <Route path="trades" element={<DashboardTradePage />} />
                                <Route path="protocol" element={<DashboardProtocolPage />} />
                                <Route path="marketing" element={<DashboardMarketingPage />} />                                
                                <Route path="users" element={<DashboardUsersPage />} />
                                <Route path="rewards" element={<DashboardRewardsPage />} />
                            </Route>
                        </Routes>
                </div>
                
            {/* )} */}
        </>
    );
}

export default App;
