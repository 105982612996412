import { useEffect, useState, useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useSkip } from 'store';


const USERS_CHART = gql`query UsersDaily ($startTimestamp: String, $endTimestamp: String, $first: Int!, $skip: Int!)  {
    userAccounts(where: {createdAt_gte: $startTimestamp
        createdAt_lte: $endTimestamp} 
        first: $first
        skip: $skip 
        orderBy: createdAt orderDirection: desc) {
      address
      createdAt
    }
  }`

//NOTE: The dayjs shall consider the timestamp in milliseconds, subgraph needs in seconds


export const generateWeeklyMarkers = (categories: string[], seriesData: number[]) => {
  const weeklyMarkers = [];
  let totalUsers = 0;

  for (let i = 0; i < categories.length; i++) {
    totalUsers += seriesData[i];
    //console.log("totalUsers and category", totalUsers, categories[i]);

    // Add marker for the end of each week or the last data point
    if ((i != 0) && ((i) % 7 === 0 || i === categories.length - 1)) {
      //const weekNumber = Math.floor(i / 7) + 1;
      weeklyMarkers.push({
        x: categories[i],
        borderColor: '#e536ab',        
        fillColor: '#c2c2c2',
        label: {
          orientation: 'horizontal',
          offsetX: -40,
          style: {
            color: '#fff',
            background: '#e536ab',
            fontWeight: 'bold',
          },
          text: `W - ${totalUsers} users`,
        },
        style: {
          background: '#fff',
          color: '#e536ab',
          fontSize: '12px',
          fontWeight: 400,
          fontFamily: undefined,
          cssClass: 'apexcharts-xaxis-annotation-label',
      },        
      });
      totalUsers = 0; // Reset for the next week
    }
  }
  return weeklyMarkers;
};

export function generateDailyDateCategories(startTimestamp: number, endTimestamp: number): string[] {
  const categories: string[] = [];
  let currentDate = dayjs(startTimestamp);
  const endDate = dayjs(endTimestamp);
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    categories.push(currentDate.format('MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }    
  return categories;
}
export const DailyUsers = (firstEpochValue:any, secondEpochValue:any) => {
    const usersDaily = new Map<string, number>();
    const [seriesCustomDaily, setDailyCustomSeriesData] = useState<any>([{  name: "Users", data: [], color: "#5c03bc", }]);
    seriesCustomDaily[0].name = "Users"; //Set this explicitly, since this was not working
    const [categories, setCategories] = useState<string[]>([]);    
    const [dataList, setDataList] = useState<Array<any>>([]);
    const {skip, setSkipVar} = useSkip();

    let generatedCategories:any;
  useEffect(() => {
    // Reset skip and first when date range changes
      setSkipVar(0);
      usersDaily.clear();
      setDataList([]);
  }, [firstEpochValue, secondEpochValue]);

  const { loading:loadingusersdaily, data:detailsdaily, refetch, fetchMore} = useQuery(USERS_CHART, {
    variables: { startTimestamp: Math.ceil(firstEpochValue).toString(), endTimestamp: Math.ceil(secondEpochValue).toString(), skip: skip, first: 1000},
    notifyOnNetworkStatusChange: true,
  });
  
  useEffect(() => {
    // Refetch data when date range changes
    refetch();      
  }, [firstEpochValue, secondEpochValue, refetch]);

  useEffect(() => {
    //console.log("DailyCustomApi query called",first,skip,detailsdaily?.userAccounts);
    if (!loadingusersdaily) {
      if (detailsdaily?.userAccounts?.length > 0) 
      {
         setDataList(prevDataList =>  {
          if (prevDataList) {
            return [...prevDataList, ...detailsdaily.userAccounts];
          } else {
            return detailsdaily.userAccounts;
          }});
        if (skip < 5000) {
          setSkipVar(skip + 1000);
        } else {
          setSkipVar(0);
          //setCount((parseInt(count) + 5000).toString());
        }        
        fetchMore({
          variables: {
            skip: skip,
            //count: count,
          },
        });
      }
    }
  }, [loadingusersdaily, detailsdaily, firstEpochValue, secondEpochValue,fetchMore]);

  const initializeUsersForCustomRange = (start: number, end: number) => {
      const startDay = dayjs(start);
      const endDay = dayjs(end);      
      const diff = endDay.diff(startDay, 'day') + 1;

      for (let i = 0; i < diff; i++) {
        const date = startDay.add(i, 'day').format('MM-DD');
        usersDaily.set(date, 0);
      }      
      //console.log("Initialize usersDaily",usersDaily);      
    };     
    useEffect(() => {
        initializeUsersForCustomRange((firstEpochValue*1000), (secondEpochValue*1000));
        //console.log("Custom details daily",dataList);                
        Promise.all (dataList?.map(async (user:any) => {
          const userDate = dayjs(user.createdAt*1000).format('MM-DD');                  
          if (usersDaily.has(userDate)) {
            usersDaily.set(userDate, (usersDaily.get(userDate) || 0) + 1);
          }
        }))
        const countsArray = Array.from(usersDaily.values());
        if(countsArray?.length) {
            //console.log("countsArray daily",countsArray);
            setDailyCustomSeriesData([{ data: countsArray }]);
        }        
        generatedCategories = generateDailyDateCategories(firstEpochValue*1000, secondEpochValue*1000);
        setCategories(generatedCategories);
    }, [dataList, firstEpochValue, secondEpochValue]);
    
    
  
    const stateCustomDaily: any = {
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: -30,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          colors: ['#5c03bc'],
          width: 4,
        },
        grid: {
          show: false,
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        xaxis: {
          type: 'category',
          categories,
          tickPlacement: 'on', 
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0,
          },         
          labels: {
            show: true,
            rotate: -45, // Rotate the labels
            rotateAlways: true, // Ensure the rotation is always applied
            style: {
              colors: '#e536ab',
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            style: {
              colors: '#e536ab',
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
          },
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: 'Users',
            style: {
              color: '#fff',
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        tooltip: {
          x: {
            show: true,
          },
        },
      },
    };

    //console.log("chart data ",seriesCustomDaily, stateCustomDaily);
    return {seriesCustomDaily,stateCustomDaily};   
}
