import { IS_MAINNET, PLYGON_ERROR_SCAN_URL } from 'config/constants';

export const useGetTxUrl = () => {
    const getTxUrl = (txHash: string | undefined) => {
        switch (IS_MAINNET) {
            case !true && txHash:
                return `${PLYGON_ERROR_SCAN_URL}${txHash}`;
            case true && !txHash:
                return `${PLYGON_ERROR_SCAN_URL}${txHash}`;
            default:
                return '';
        }
    };

    return { getTxUrl };
};

export default useGetTxUrl;
