import { DetailsWithTooltip } from 'components/Common';
import { interFormat, shortFormat, truncateToTwoDecimalPlaces, useWindowSize } from 'utils';
import LeaderboardPage from '../LeaderboardPage/LeaderboardPage';
import { ReactComponent as LongPositions } from 'assets/svg/LongPosition.svg';
import { ReactComponent as ShortPositions } from 'assets/svg/ShortPosition.svg';
import { ReactComponent as MakerFees } from 'assets/svg/MakerFees.svg';
import { ReactComponent as TakerFees } from 'assets/svg/TakerFees.svg';
import { ReactComponent as User } from 'assets/svg/User.svg';
import { ReactComponent as OI } from 'assets/svg/OI.svg';
import { useEffect, useState, useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { formatUnits } from 'utils/formatUnits';

const GET_DASHBOARD_DETAILS = gql`
        query GetDashboardDetails($first: Int!, $skip: Int!) {
            userAccounts (first: $first
                skip: $skip) {
                id
                address
                totalPositions (where: {status: Open} ) {
                    id
                    side
                }
            }    
            protocolStats {
                totalMakerFeeGenerated
                totalTakerFeeGenerated
                openInterest
            }
        }`;

const Details = () => {
    const [skip, setSkip] = useState(0);
    const [first, setFirst] = useState(1000);
    const [dataList, setDataList] = useState([]);

    const { loading, error, data, fetchMore } = useQuery(GET_DASHBOARD_DETAILS, {
        variables: { skip: skip, first: first},
        notifyOnNetworkStatusChange: true,
    });
    useEffect(() => {
        if (!loading) {
          if (data?.userAccounts?.length > 0) 
          {      
            //console.log("Dashboard first and skip",first,skip);        
            setDataList(prevDataList =>  {
              if (prevDataList) {
                return [...prevDataList, ...data.userAccounts];
              } else {
                return data.userAccounts;
              }});
            setSkip(prevSkip => {
                if (prevSkip < 5000) {
                  return prevSkip + 1000;
                } else {
                  return 0;
                }
            });
        
            setFirst(1000);
        
            // if (skip < 5000) {
            //   setSkip(skip + 1000);
            //   setFirst(1000);
            // } else {
            //   setSkip(0);
            //   setFirst(1000);
            // }
            //console.log("count and skip",skip);
            fetchMore({
              variables: {
                skip: skip,
              },
            });
          }
        }
      }, [data,fetchMore]);
     // console.log("dashboard datalist",dataList,data);

    // Initialize total positions
    let totalLongPos = 0;
    let totalShortPos = 0;
    const ldata = dataList?.map((account:any,index)=>{
        //console.log("account is",account);
      let numlongPos:any = 0;
      let numshortPos:any = 0;
     
      account?.totalPositions?.forEach((position:any) => {
        if (position.side === 'Long') {
            numlongPos++;
        } else if (position.side === 'Short') {
            numshortPos++;
        }
        //console.log("numlongPos numshortPos",numlongPos,numshortPos);
      });
      // Add to totalLongPos and totalShortPos
      totalLongPos += isNaN(numlongPos) ? 0 : numlongPos;
      totalShortPos += isNaN(numshortPos) ? 0 : numshortPos;
              
  
    })
    //console.log("totalLongPos totalShortPos",totalLongPos,totalShortPos)
    return (    
        <div className="w-[100%] min-w-[1300px] p-6 pl-50 theme-gradient rounded-[8px] pb-4">
            <div className="text-[18px] font-inter text-white">Dashboard</div>
            <div className="w-full h-[1px] bg-[#ffffff75] opacity-80 my-4" />
            <div className="grid grid-cols-6 4xl:grid-cols-6 gap-4">
                <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <User/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {dataList.length}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        # User                       
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"User"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <LongPositions/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {totalLongPos}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        # Long Positions                          
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Long positions"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <ShortPositions/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {totalShortPos}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        # Short Positions                          
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Short positions"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <MakerFees/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {shortFormat(formatUnits(data?.protocolStats[0]?.totalMakerFeeGenerated))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Maker Fees                        
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Maker Fees"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <TakerFees/>
                    </span>
                    <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                            {shortFormat(formatUnits(data?.protocolStats[0]?.totalTakerFeeGenerated))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Taker Fees                        
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Taker Fees"} />
                        </span>
                    </div>
                    
               </div>               
               <div className="p-6 pt-[18px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <OI/>
                    </span>
                    <div className="text-white pt-[12px] text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {shortFormat(formatUnits(data?.protocolStats[0]?.openInterest))+" SNV"}
                    </div>
                    <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                        Open Interest                      
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Open Interest"} />
                        </span>
                    </div>
                    
               </div>
            </div>
        </div>
    );
};
const DashboardDetailsPage = () => {
    const maxWidth = 679;
    const { windowInnerWidth } = useWindowSize();
    return (
        <div>
            {!windowInnerWidth ? null : windowInnerWidth >= maxWidth ? (
                <div className="flex gap-4 p-[1rem] flex-col">
                    <div className="flex  w-full gap-y-2 ">
                        <Details />
                    </div>                    
                    <div className="flex w-full gap-y-2">
                        <LeaderboardPage />
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 p-[1rem]">
                    {/* <LeaderboardPage />                     */}
                </div>
            )}
        </div>
    );
};

export default DashboardDetailsPage;
