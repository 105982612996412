export const convertToEpoch = (
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
    millisecond: number
) => {
    const date = new Date(year, month, day, hour, minute, second, millisecond);
    return date.getTime()/ 1000;
};

export default convertToEpoch;
