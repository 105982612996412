import React, { createContext, useContext, useReducer } from 'react';
import { GenericToastComponent } from 'components/Common/Toast/GenericNotificationToast';

/// Context Interface
interface toastContextInterface {
    toastDispatch: React.Dispatch<NotificationReducerActions>;
}

/// ToastContext which passes the State and Dispatch Functions
const ToastContext = createContext<toastContextInterface | null>(null);

/// Toast Interface Data that is passed to Generic Notification Component
export interface toastData {
    id?: number;
    type?: 'Error' | 'Success';
    content?: string;
    data?: string;
    messageHead?: string;
    isTransaction?: boolean;
}

/// Initial State for Toasts
/// This is made as an array to dispaly all notification
/// Extra Logic can be implemented to show more and more notifications
const initialState: Array<toastData> = [];

/// Notification Actions that can be implemented
export enum NotificationActions {
    ADD = 'Add',
    REMOVE = 'Remove'
}

/// Dispatch Interface or the function that is actually called by the dispatch and the data required by it
interface NotificationReducerActions {
    type: NotificationActions;
    payload: toastData;
}

/// Reducer Function to  handle all state dispatches and work on them according to the needs
const toastReducer = (state: Array<toastData>, action: NotificationReducerActions) => {
    switch (action.type) {
        case NotificationActions.ADD: {
            return [
                ...state,
                {
                    id: +new Date(),
                    content: action.payload.content,
                    type: action.payload.type,
                    isTransaction: action.payload.isTransaction,
                    data: action.payload.data,
                    messageHead: action.payload.messageHead
                }
            ];
        }
        case NotificationActions.REMOVE: {
            return state.filter((t) => t.id !== action.payload.id);
        }
    }
};

/// Functional Component with the Wrapped Context and Provider to ensure context is available throught
/// the app
export const NotificationProvider = (props: any) => {
    const [toast, toastDispatch] = useReducer(toastReducer, initialState);
    // Removing toast here to optimize rerenders as toast data change would trigger a rerender
    // in all child components this is avoided as the data itself is not changed in any of the components
    const toastData = { toastDispatch };
    return (
        <ToastContext.Provider value={toastData}>
            {props.children}
            <GenericToastComponent toast={toast} />
        </ToastContext.Provider>
    );
};

/// Helper Function to get Context in any component
export const useToastContext = () => {
    return useContext(ToastContext);
};

export default NotificationProvider;
