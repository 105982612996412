import { formatEther } from 'viem';

export const formatUnits = (value: string) => {
    try {
        if (value === undefined) {
            return '0';
        }
        //console.log("formatunits value",value);
        // Math.floor truncate the decimal part of the number
        return formatEther(BigInt(Math.round(Number(value))));
    } catch (err) {        
        return formatEther(BigInt(Math.round(Number(value))));
    }
};
export default formatUnits;
