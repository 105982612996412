// import { CSSProperties, useMemo } from 'react';
import { DetailsWithTooltip } from 'components/Common';
import { shortFormat, useWindowSize } from 'utils';
import { ReactComponent as NetLongAmt } from 'assets/svg/NetLongAmt.svg';
import { ReactComponent as NetShortAmt } from 'assets/svg/NetShortAmt.svg';
import { ReactComponent as NetTakerOrder } from 'assets/svg/NetTakerOrder.svg';
import { ReactComponent as NetNewPos } from 'assets/svg/NetNewPos.svg';
import { ReactComponent as NotionalValShort } from 'assets/svg/NotionalValShort.svg';
import { ReactComponent as NotionalValLong } from 'assets/svg/NotionalValLong.svg';
//import { ReactComponent as NotionalValLong } from 'assets/svg/NotionalValLong.svg';

import { useEffect, useState, useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { formatUnits } from 'utils/formatUnits';
import { getTime } from './utils/date';
import dayjs from 'dayjs';
import { ButtonInterface } from '../../interfaces/DatePage';
import Button from './Button';
import { ReactComponent as Calender } from 'assets/svg/calender.svg';
import { fetchEthPrice } from './utils/price';

const GET_TRADER_POSITIONS = gql`
query TraderPositions($timestamp: String, $side: String) {    
    positions(
      where: {status: Open, startTimestamp_gt: $timestamp, side: $side}
      first: 1000
      orderDirection: desc
      orderBy: startTimestamp
    ) {
      amount
    }
  } 
`;

const GET_NEW_OPEN_POSITIONS = gql`
query NewOpenPositions($timestamp: String) {    
    positions(
      where: {status: Open, startTimestamp_gt: $timestamp}
      first: 1000
    ) {
      id
    }
  } 
`;

const GET_TRADER_TAKER_ORDER = gql`
query TraderTakerOrder($timestamp: String) {
    takerOrders(orderDirection: desc, where: {timestamp_gt: $timestamp}) {
      amount
    }
  } 
`;
const GET_MARKPRICE = gql`query {
    markPrices(orderBy: id, orderDirection: desc, first: 1) {
      id
      MarkPrice
    }
  }`;
const preSetButtons: Array<ButtonInterface> = [
    {
        text: '1D',
        isActive: true
    },
    {
        text: '7D',
        isActive: false
    },
    {
        text: '1M',
        isActive: false
    },
    {
        text: '3M',
        isActive: false
    },
    {
        text: '6M',
        isActive: false
    }
];

const Details = () => {
    const [buttons, setButtons] = useState<Array<ButtonInterface>>(preSetButtons);
    const [timestampVal, setTimeStampButton] = useState('');
    const date = dayjs().subtract(6, 'months');
    const [netLongAmt, setNetLongAmount] = useState(0);
    const [netShortAmt, setNetShortAmount] = useState(0);
    const [netNewPositions, setNewOpenPosition] = useState(0);
    const [netTakerOrderAmt, setNetTakerOrder] = useState(0);
    const [ethPrice, setEthPrice] = useState(null);
    //const ALL_TIME_TIMESTAMP = "43084000";
    const { loading:netlongloading,data:netlongpos } = useQuery(GET_TRADER_POSITIONS, {
        variables: { timestamp: timestampVal, side: 'Long' },
        notifyOnNetworkStatusChange: true,
    });
    const { loading:netshortloading,data:netshortpos } = useQuery(GET_TRADER_POSITIONS, {
        variables: { timestamp: timestampVal, side: 'Short' },
        notifyOnNetworkStatusChange: true,
    });
    //GET_NEW_OPEN_POSITIONS
    const { loading:newposloading,data:newopenpos } = useQuery(GET_NEW_OPEN_POSITIONS, {
        variables: { timestamp: timestampVal },
        notifyOnNetworkStatusChange: true,
    });
    const { loading:loadingmp, data:markprice} = useQuery(GET_MARKPRICE, {
        notifyOnNetworkStatusChange: true,
    });
    const { loading:loadingtakerorder, data:nettakerorder} = useQuery(GET_TRADER_TAKER_ORDER, {
        variables: { timestamp: timestampVal },
        notifyOnNetworkStatusChange: true,
    });
    
    //console.log("netlongpos",netlongpos,timestampVal)
    useEffect(() => {
        if(!netlongloading) {
            if (netlongpos?.positions && netlongpos?.positions.length > 0) {
                const sumOfAmounts = netlongpos.positions.reduce((acc:any, newd:any) => {
                  const amountAsInt = parseInt(newd.amount);
                  return acc + amountAsInt;
                }, 0);
                //console.log("sumOfAmounts",sumOfAmounts);            
                setNetLongAmount(sumOfAmounts)
              }else {
                setNetLongAmount(0)
              }    
        }
    }, [netlongloading, netlongpos, timestampVal]);
    
    useEffect(() => {
        if(!netshortloading) {
            if (netshortpos?.positions && netshortpos?.positions.length > 0) {
                const sumOfAmounts = netshortpos.positions.reduce((acc:any, newd:any) => {
                  const amountAsInt = parseInt(newd.amount);
                  return acc + amountAsInt;
                }, 0);
                //console.log("sumOfAmounts",sumOfAmounts);
                setNetShortAmount(sumOfAmounts)
              } else {
                setNetShortAmount(0)
              }
    
        }
    }, [netshortloading, netshortpos, timestampVal]);
    
    useEffect(() => {
        if(!newposloading) {
            if (newopenpos?.positions && newopenpos?.positions.length > 0) {
                setNewOpenPosition(newopenpos?.positions.length)
            } else {
                setNewOpenPosition(0)
            }

        }
    }, [newposloading, newopenpos, timestampVal]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const price = await fetchEthPrice();
            setEthPrice(price);
          } catch (error) {
            console.error('Error fetching Ethereum price:', error);
          }
        };
    
        fetchData();
      }, []); 

    useEffect(() => {
        if(!loadingtakerorder) {
            if (nettakerorder?.takerOrders && nettakerorder?.takerOrders.length > 0) {
                //console.log("nettakerorder",nettakerorder)
                const sumOfAmounts = nettakerorder.takerOrders.reduce((acc:any, newd:any) => {
                    const amountAsInt = parseInt(newd.amount);
                    //return acc + formatUnits(amountAsInt);
                    return acc + amountAsInt;
                }, 0);
                setNetTakerOrder(sumOfAmounts)
            } else {
                setNetTakerOrder(0)
            }   
        }
    }, [loadingtakerorder, nettakerorder, timestampVal]);

    const handleButtonSelect = (button: '1D' | '7D' | '1M' | '3M' | '6M') => {
        
        const fromDate = getTime(button);
        //console.log("Date ",fromDate?.Date.toString());
       
        for (let i = 0; i < preSetButtons.length; i++) {
            if (preSetButtons[i].text === button) {
                preSetButtons[i].isActive = true;
                if(fromDate?.Date)
                    setTimeStampButton(fromDate?.Date.toString());
                continue;
            }
            preSetButtons[i].isActive = false;
        }
        setButtons(preSetButtons);
    };
   

    preSetButtons.forEach((item) => {
        item.onClickCallback = handleButtonSelect;
    });   
    
    //console.log("markprice",markprice?.markPrices[0].MarkPrice,netLongAmt,ethPrice);
    let netNotionalLongVal:any = 0;
    let netNotionalShortVal:any = 0;
    if (ethPrice) {        
        netNotionalLongVal = isNaN(markprice?.markPrices[0].MarkPrice) ? '' : shortFormat(formatUnits((netLongAmt * (markprice?.markPrices[0].MarkPrice) * ethPrice).toString()));
        netNotionalShortVal = isNaN(markprice?.markPrices[0].MarkPrice) ? '' : shortFormat(formatUnits((netShortAmt * (markprice?.markPrices[0].MarkPrice) * ethPrice).toString()));
    }
    //const netNotionalLongVal:any = netLongAmt * (markprice?.markPrices[0].MarkPrice) * ethPrice;//0;//isNaN(Number(netLongAmt)) ? 0 : (netLongAmt*markprice*ethPrice);
    //const netNotionalShortVal:any = 0;//isNaN(Number(netShortAmt)) ? 0 : (netShortAmt*markprice*ethPrice);
    //console.log("netNotionalShortVal",netNotionalShortVal,netNotionalLongVal);
    return (    
        <div className="w-[100%] max-w-[1679px] p-6 pl-50 theme-gradient rounded-[8px] pb-4">
            <div className=" text-[18px] font-inter text-white">Trade Statistics</div>
            <div className="w-full h-[1px] bg-[#ffffff75] opacity-80 my-4" />
            <div className="text-[#E0E0E0] text-[15px] font-normal ">
                        <div className="gap-4 flex items-center  pb-[12px] pt-[12px]">
                            <span className="pl-[8px]">
                                <Calender/>
                            </span>
                            {buttons.map((value) => {
                                return (
                                    <Button
                                        selected={value.isActive}
                                        className={`text-[15px] cursor-pointer`}
                                        text={value.text}
                                        key={value.text}
                                        onClick={() => {
                                            value.onClickCallback(value.text);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
            <div className="grid grid-cols-6 4xl:grid-cols-6 gap-4">
            
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <NetLongAmt/>
                    </span>
                    <div className="text-white text-[18px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {shortFormat(formatUnits(netLongAmt.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[12px] font-inter flex justify-start items-center gap-1 ">
                        Net Long Amount                          
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Short positions"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <NetShortAmt/>
                    </span>
                    <div className="text-white text-[18px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {shortFormat(formatUnits(netShortAmt.toString()))+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[12px] font-inter flex justify-start items-center gap-1 ">
                        Net Short Amount                          
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Short positions"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <NetNewPos/>
                    </span>
                    <div className="text-white text-[18px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {netNewPositions}
                    </div>
                    <div className="text-[#E8E8E8] text-[12px] font-inter ">
                        <span className="flex items-center">
                            # New Open Positions                        
                            <span className="">
                                <DetailsWithTooltip tooltipText={"Number of new open positions"} />
                            </span>
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <NotionalValLong/>
                    </span>
                    <div className="text-white text-[18px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                            {netNotionalLongVal + " SNV"}
                    </div>
                    <div className="text-[#E8E8E8] text-[12px] font-inter gap-1 ">
                        <span className="flex items-center">
                            Net Notional Value(Long)                     
                        
                            <DetailsWithTooltip tooltipText={"Taker Fees"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[14px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <NotionalValShort/>
                    </span>
                    <div className="text-white text-[18px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {netNotionalShortVal + " SNV"}
                    </div>
                    <div className="text-[#E8E8E8] text-[12px] font-inter flex justify-start items-center gap-1 ">
                        <span className="flex items-center">
                            Net Notional Value(Short)                      
                        
                            <DetailsWithTooltip tooltipText={"User"} />
                        </span>
                    </div>
                    
               </div>
               <div className="p-6 pt-[18px] w-full h-[129px] bg-[#10141B] rounded-[10px]">
                    <span className="">
                        <NetTakerOrder/>
                    </span>
                    <div className="text-white text-[18px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                        {formatUnits((netTakerOrderAmt).toString())+" WETH"}
                    </div>
                    <div className="text-[#E8E8E8] text-[12px] font-inter flex justify-start items-center gap-1 ">
                        Net Taker Orders
                        <span className="mt-1">
                            <DetailsWithTooltip tooltipText={"Taker Fees"} />
                        </span>
                    </div>
                    
               </div>
               
            </div>
        </div>
    );
};
const DashboardTradePage = () => {
    const maxWidth = 679;
    const { windowInnerWidth } = useWindowSize();
    return (
        <div>
            {!windowInnerWidth ? null : windowInnerWidth >= maxWidth ? (
                <div className="flex gap-4 p-[1rem] flex-col">
                    <div className="flex  w-full gap-y-2">
                        <Details />
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 p-[1rem]">
                    {/* <LeaderboardPage />                     */}
                </div>
            )}
        </div>
    );
};

export default DashboardTradePage;
