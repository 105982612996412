import React, { Component, ReactElement } from 'react';
import PropTypes from 'prop-types';
import HistoryTab from './HistoryTab';

interface TabsProps {
    children: ReactElement<HistoryTab>[];
}

interface TabsState {
    activeTab: string;
}

class Tabs extends Component<TabsProps, TabsState> {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired
    };

    constructor(props: TabsProps) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label
        };
    }

    onClickTabItem = (tab: string) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const {
            onClickTabItem,
            props: { children },
            state: { activeTab }
        } = this;

        return (
            <div>
                <div className="flex gap-x-[38px] my-[15px] cursor-pointer items-center">
                    {children.map((child) => {
                        const { label }: any = child.props;

                        return (
                            <HistoryTab
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                onClick={onClickTabItem}
                            />
                        );
                    })}
                </div>
                <div>
                    {children.map((child) => {
                        if (child.props.label !== activeTab) return null;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

export default Tabs;
