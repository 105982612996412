import { createContext, useContext, useEffect, useState } from 'react';

/// Screen Context to propogate width changes into the tree
const ScreenWidthContext = createContext<number>(window.innerWidth);

/// Functional Component to ensure only one listener is required to listen for width changes and can be
/// broadcasted to the entire app
export const ScreenWidthProvider = (props: any) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add an event listener to update screenWidth when the window is resized
        window.addEventListener('resize', updateScreenWidth);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, [window.innerWidth]);

    return <ScreenWidthContext.Provider value={screenWidth}>{props.children}</ScreenWidthContext.Provider>;
};

/// Helper Function to get Context in any component
export const useScreenWidthContext = () => {
    return useContext(ScreenWidthContext);
};

export default ScreenWidthProvider;
