import React from 'react';
import { toastData } from 'context';
import Toast from './Toast';

/// React FC to dispaly Toast based on the reducer disaptch type
export const GenericToastComponent = ({ toast }: { toast: Array<toastData> }) => {
    /// notification value taken from the toast array
    const notification = toast.pop();
    if (!notification) return null;


    /// Return Toast based on notification type to extend any component just add more fields
    /// to the requried component and update in reducer
    return (
        <>
            {
                <Toast
                    isTransaction={notification.isTransaction ? notification.isTransaction : false}
                    messageHead={notification.messageHead || ''}
                    data={notification.data}
                    messageContent={notification.content}
                    type={notification.type || ''}
                />
            }
        </>
    );
};
