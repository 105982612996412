/* eslint-disable */
import { ApolloClient , InMemoryCache, HttpLink } from '@apollo/client';

const APIURL = 'https://api.studio.thegraph.com/query/80821/nftfn-internal/version/latest';
//const APIURL = 'https://thegraph.com/hosted-service/subgraph/gulshanvas/bliv-internal';
//const APIURL = 'https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?hl=en-GB&utm_source=ext_sidebar';
const httpLink = new HttpLink({
  uri: APIURL
});

/// This ApolloClient is made without a Header or Authentication Token
/*export const apolloClient = new ApolloClient({
    uri : httpLink,
    cache : new InMemoryCache(),
})*/
export const apolloClient = new ApolloClient({
    link: httpLink,
    cache : new InMemoryCache(),
  });