import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RainbowProvider, CustomContextProvider } from './context';
import { apolloClient } from 'graphql/ApolloClient';
import { ApolloProvider } from '@apollo/client';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    }
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <SnackbarProvider>
        <RainbowProvider>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CustomContextProvider>
                        <ApolloProvider client={apolloClient}>
                            <App />
                        </ApolloProvider>
                    </CustomContextProvider>
                </ThemeProvider>
            </BrowserRouter>
        </RainbowProvider>
    </SnackbarProvider>
);
