import React from 'react';
interface ButtonProps {
    text: string;
    onClick: () => void;
    className: string;
    selected: boolean;
}

const Button = ({ text, onClick, className, selected }: ButtonProps) => {
    return (
        <div onClick={onClick} className={`${className} ${selected ? 'text-[#BDB4F5] font-bold' : 'white'} `}>
            {text}
        </div>
    );
};

export default Button;
