import {create} from 'zustand';
import {persist} from 'zustand/middleware';


export const useReferrerAddress = create (set => ({
  refAddr: '',
  setReferrerAddress: refAddr => set (() => ({refAddr})),
}));

export const useSkip = create (set => ({
  skip: 0,
  setSkipVar: skip => set (() => ({skip})),
}));
