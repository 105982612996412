import React from 'react';
import { RxTriangleUp, RxTriangleDown } from 'react-icons/rx';

const IncDecTriangle = React.memo(() => {
    return (
        <>
            <div className="flex flex-col justify-center items-center">
                <RxTriangleUp className="relative top-[5px] " style={{ height: '15px', width: '15px' }} />
                <RxTriangleDown className="relative bottom-[5px]" style={{ height: '15px', width: '15px' }} />
            </div>
        </>
    );
});

IncDecTriangle.displayName = 'IncDecTriangle';
export default IncDecTriangle;
