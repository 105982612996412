import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LeaderboardTableRow from './LeaderboardTableRow';
import Button from './Button';
import { convertToEpoch } from 'utils/epochTime';
import { ButtonInterface, StartingValuesInterface } from '../../interfaces/DatePage';
import { useScreenWidthContext } from 'context';
import { getTime } from './utils/date';
import { ReactComponent as Calender } from 'assets/svg/calender.svg';
import { sizeSort, timeSort } from './utils/filters';
import { IncDecTriangle } from 'components/Common';

interface LeaderboardTablePageProps {
    tableData: any;
    loading: boolean;
    preSetButtons: Array<ButtonInterface>;
}
interface Filters {
    orderBy: boolean;
    sizeSorted: 'ASC' | 'DESC' | 'NONE';
    sortType: 'points' | 'rpnl' | 'fee' | 'trades' | 'upnl' | 'roi' | 'lvol' | 'svol' | 'none';
    checkBoxTickAll: boolean;
}
const LeaderboardTablePage = ({ tableData, loading, preSetButtons }: LeaderboardTablePageProps) => {
    const [filters, setFilters] = useState<Filters>({
        orderBy: true,
        sizeSorted: 'NONE',
        sortType: 'none',
        checkBoxTickAll: true
    });
    let data: any;
    const [filteredData, setFilteredData] = useState<Array<any>>([]);
    const [currentData, setCurrentData] = useState<Array<any>>();
    const [pageCount, setPageCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    //const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
    //const { isConnected } = useAccount();
    const screenWidth = useScreenWidthContext();
    const itemsPerPage = Math.floor((window.innerHeight - 520) / 42.2);
    const startOfDay = dayjs().startOf('day');
    //const ribbonContext = useRibbonContext();
    const [startingDateValues, setStartingDateValues] = useState<StartingValuesInterface>({
        firstDateValue: startOfDay,
        firstEpochValue: startOfDay.toDate().getTime() / 1000,
        secondDateValue: startOfDay,
        secondEpochValue: startOfDay.toDate().getTime() / 1000
    });

    const handleButtonSelect = (button: 'ALL' | '1D' | '7D' | '1M' | '3M' | '6M') => {
        const fromDate = getTime(button);
        //console.log("fromDate",fromDate, startingDateValues);
        setStartingDateValues({
            ...startingDateValues,
            firstDateValue: fromDate.dayJS,
            firstEpochValue: fromDate.Date
        });

        for (let i = 0; i < preSetButtons.length; i++) {
            if (preSetButtons[i].text === button) {
                preSetButtons[i].isActive = true;
                if (preSetButtons[i].text === 'ALL') {
                    setFilters({
                        ...filters,
                        checkBoxTickAll: true
                    });
                } else {
                    setFilters({
                        ...filters,
                        checkBoxTickAll: false
                    });
                }
                continue;
            }
            preSetButtons[i].isActive = false;
        }
        setButtons(preSetButtons);
    };
    //console.log("startingDateValues",startingDateValues);
    // Pass Buttons as props and set their onClickCallbacks specific to the page being rendered
    preSetButtons.forEach((item) => {
        item.onClickCallback = handleButtonSelect;
    });

    const [buttons, setButtons] = useState<Array<ButtonInterface>>(preSetButtons);

    const updateFilterData = (filterData: Array<any>) => {
        setFilteredData(filterData);
        setPageCount(Math.ceil(filterData.length / itemsPerPage));
        setCurrentData(filterData.slice(0, itemsPerPage));
    };

    // Re-render in case the no of items change
    useEffect(() => {
        if (tableData) updateFilterData(tableData);
    }, [itemsPerPage]);

    useEffect(() => {
        if (!loading) {
            setSelectedPage(0);
            if (tableData) {
                data = tableData;
                const filterData = data.filter((item: any) => {
                    if (filters?.checkBoxTickAll) {
                        //console.log("item All",item);
                        return item;
                    } else {
                        //console.log("timestamp vals",item?.timestamp,startingDateValues.firstEpochValue, startingDateValues.secondEpochValue);
                        return (
                            +item?.timestamp > startingDateValues.firstEpochValue &&
                            +item?.timestamp < startingDateValues.secondEpochValue
                        );
                    }
                });
                if (filters.sizeSorted != 'NONE') {
                    //console.log("sort called",filterData,filters.sizeSorted);
                    filterData.sort((a: any, b: any) => sizeSort(a, b, filters.sizeSorted, filters.sortType));
                }
                //console.log("filterData",filterData,startingDateValues.firstEpochValue,startingDateValues.secondEpochValue);
                updateFilterData(filterData);
            }
        } else {
            setPageCount(0);
        }
    }, [tableData, startingDateValues, filters]);

    const handlePageClick = (data: any) => {
        const selectedPage = data.selected;
        setSelectedPage(data.selected);
        setCurrentData(
            filteredData?.slice(selectedPage * itemsPerPage, selectedPage * itemsPerPage + itemsPerPage)
        );
    };

    const changeDate = (val: any, buttonType: '1' | '2') => {
        const epochTime = convertToEpoch(val?.$y, val?.$M, val?.$D, val?.$H, val?.$m, val?.$s, val?.$ms);
        //console.log("epochTime val",epochTime,val);
        buttonType === '1'
            ? setStartingDateValues({
                  ...startingDateValues,
                  firstEpochValue: epochTime
              })
            : setStartingDateValues({
                  ...startingDateValues,
                  secondEpochValue: epochTime
              });
        setFilters({
            ...filters,
            checkBoxTickAll: false
        });
        //console.log("startingDateValues",startingDateValues);
    };
    //console.log("currentData",currentData);
    return (
        <>
            <div className="border-solid border-[#4B4A5E] text-[#BDB4F5] text-[20px] font-medium overflow-hidden w-full">
                Leaderboard
                <div className="flex justify-between">
                    <div className="text-[#E0E0E0] text-[15px] font-normal ">
                        <div className="gap-4 flex items-center  pb-[12px] pt-[12px]">
                            <span className="pl-[8px]">
                                <Calender />
                            </span>
                            {buttons.map((value) => {
                                return (
                                    <Button
                                        selected={value.isActive}
                                        className={`text-[15px] cursor-pointer`}
                                        text={value.text}
                                        key={value.text}
                                        onClick={() => {
                                            value.onClickCallback(value.text);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex justify-end items-center gap-3 ">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <div className="w-[155px] mt-[-11px] overflow-y-hidden overflow-x-hidden">
                                    <DatePicker
                                        onChange={(newValue: any) => {
                                            changeDate(newValue, '1');
                                        }}
                                        className="relative bg-[white] text-[white] p-[5.5px] pl-[8px] rounded-[6px] text-left w-[120px] text-[14px] font-inter font-normal "
                                        format="DD-MMM-YYYY"
                                        disableFuture
                                        views={['year', 'month', 'day']}
                                        value={startingDateValues.firstDateValue}
                                    />
                                </div>
                            </DemoContainer>
                        </LocalizationProvider>
                        <span className="text-[14px] font-medium text-[#C5C5C5] font-inter ">to</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <div className="w-[155px] mt-[-11px] overflow-y-hidden overflow-x-hidden ">
                                    <DatePicker
                                        onChange={(newValue: any) => {
                                            changeDate(newValue, '2');
                                        }}
                                        className="relative border border-[#2B2B45] bg-[white] text-[white] p-[5.5px] pl-[8px] rounded-[6px] text-left w-[120px] font-inter text-[14px] font-normal "
                                        format="DD-MMM-YYYY"
                                        disableFuture
                                        views={['year', 'month', 'day']}
                                        value={startingDateValues.secondDateValue}
                                    />
                                </div>
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </div>
                {/* </div> */}
                {/* <div className="w-[100%] max-w-[1678px]"> <div className="flex border-y py-[15px] px-[2rem] border-[#4B4A5E] max-w-[983px] mx-auto ">*/}
                <div className="overflow-x-auto">
                    <div className="w-[100%] min-w-[1800px]">
                        <div className="grid grid-cols-12 border-y border-[#4B4A5E] gap-6 py-[12px] items-center px-3 text-[1.125rem] font-medium font-inter text-[#B0B1C5]">
                            {/* <div className="flex  grid border-y border-[#4B4A5E] grid-cols-10 py-[12px] items-center px-3 text-[1.125rem] font-medium font-inter text-[#B0B1C5]"> */}
                            <div className="text-start">S.No.</div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'points',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                Points
                                <IncDecTriangle />
                            </div>
                            <div className="text-start">User</div>
                            <div className="text-start">Referrals</div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'roi',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                ROI
                                <IncDecTriangle />
                            </div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'fee',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                Fee
                                <IncDecTriangle />
                            </div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'trades',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                Trades
                                <IncDecTriangle />
                            </div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'rpnl',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                RealizedP&L
                                <IncDecTriangle />
                            </div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'upnl',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                UnRealizedP&L
                                <IncDecTriangle />
                            </div>
                            <div className="text-center">Margin balance</div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'lvol',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                LongTrades
                                <IncDecTriangle />
                            </div>
                            <div
                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        sortType: 'svol',
                                        sizeSorted:
                                            filters.sizeSorted === 'NONE'
                                                ? 'ASC'
                                                : filters.sizeSorted === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                    });
                                }}
                            >
                                ShortTrades
                                <IncDecTriangle />
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                    {currentData?.length !== 0 ? (
                        <div className={`text-white mx-auto pt-[12px] min-w-[1800px] w-full`}>
                            <div>
                                {loading ? (
                                    <div>
                                        {Array(10)
                                            .fill('8')
                                            .map((item, index) => {
                                                return (
                                                    <LeaderboardTableRow
                                                        key={index}
                                                        item={item}
                                                        loading={loading}
                                                    />
                                                );
                                            })}
                                    </div>
                                ) : (
                                    <div>
                                        {currentData?.map((item: any) => {
                                            return (
                                                <LeaderboardTableRow
                                                    key={item?.id}
                                                    item={item}
                                                    loading={loading}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className={`flex h-[65px]`}>
                    {pageCount > 0 && (
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            renderOnZeroPageCount={undefined}
                            containerClassName="pagination-container"
                            pageLinkClassName="pagination-num"
                            previousLinkClassName="pagination-num"
                            nextLinkClassName="pagination-num"
                            activeLinkClassName="pagination-active"
                            forcePage={selectedPage}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
export default LeaderboardTablePage;
