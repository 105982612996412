import { ReactComponent as DetailsIcon } from 'assets/svg/details-icon.svg';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: '13.5px',
        background: '#11151B',
        border: '1px solid #CECFD1',
        padding: '10px',
        borderRadius: '5px',
        fontWeight: 'normal',
        fontFamily: 'Inter'
    }
});

interface DetailsWithTooltipProps {
    tooltipText?: string;
}

const DetailsWithTooltip = ({ tooltipText }: DetailsWithTooltipProps) => {
    return (
        <div>
            <CustomWidthTooltip arrow placement="top" title={tooltipText}>
                <DetailsIcon className="cursor-pointer" />
            </CustomWidthTooltip>
        </div>
    );
};

export default DetailsWithTooltip;
