import dayjs from 'dayjs';

export function getTime(offset: '7D' | '2W' | '1M' | '3M') {
    let date;
    switch (offset) {
        case '2W': {
            //console.log("1D printing");            
            date = dayjs().subtract(2, 'week').startOf('day');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        // case '1D': {
        //     //console.log("1D printing");
        //     date = dayjs().subtract(1, 'day').startOf('day');
        //     return {
        //         dayJS: date,
        //         Date: Math.ceil(date.toDate().getTime() / 1000)
        //     };
        // }
        case '7D': {
            date = dayjs().subtract(7, 'days').startOf('day');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        case '1M': {
            date = dayjs().subtract(1, 'month').startOf('day');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        case '3M': {
            date = dayjs().subtract(3, 'months').startOf('day');
            return {
                dayJS: date,
                Date: Math.ceil(date.toDate().getTime() / 1000)
            };
        }
        // case '6M': {
        //     date = dayjs().subtract(6, 'months').startOf('day');
        //     return {
        //         dayJS: date,
        //         Date: Math.ceil(date.toDate().getTime() / 1000)
        //     };
        // }
    }
}
