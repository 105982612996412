import React, { Component } from 'react';

interface TabProps {
    activeTab: string;
    label: string;
    // eslint-disable-next-line no-unused-vars
    onClick: (label: string) => void;
}

class Tab extends Component<TabProps> {
    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    };
    label: any;
    children: any;

    render() {
        const {
            onClick,
            props: { activeTab, label }
        } = this;

        return (
            <div
                className={`font-inter cursor-pointer ${
                    activeTab === label
                        ? 'text-[#BDB4F5] font-bold text-[24px]'
                        : 'text-[#ffffff] font-medium opacity-40 text-[20px] '
                }`}
                onClick={onClick}
            >
                {label}
            </div>
        );
    }
}

export default Tab;
