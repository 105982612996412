import '@rainbow-me/rainbowkit/styles.css';
import React from 'react';
import { darkTheme, getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, WagmiConfig, createConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { ALCHEMY_ID, WALLETCONNECT_PROVIDER_ID, TOKEN_CLAIM_CHAIN_ID, ALCHEMY_TESTNET_ID } from 'config/constants';
import { baseSepolia, base } from 'wagmi/chains';

export interface LayoutProps {
    children: React.ReactNode;
}
const RainbowProvider = ({ children }: LayoutProps) => {
    //const suppoertedChain = CHAIN_ID === 137 ? polygon : polygonMumbai;
    const { chains, publicClient } = configureChains(
        [baseSepolia, base],
        [alchemyProvider({ apiKey: TOKEN_CLAIM_CHAIN_ID === 1 ? ALCHEMY_ID : ALCHEMY_TESTNET_ID }), publicProvider()],
        { stallTimeout: 2_000 }
    );

    const { connectors } = getDefaultWallets({
        appName: 'nftfn.xyz',
        projectId: WALLETCONNECT_PROVIDER_ID,
        chains
    });

    const config = createConfig({
        autoConnect: true,
        connectors,
        publicClient: publicClient
    });

    return (
        <WagmiConfig config={config}>
            <RainbowKitProvider
                theme={darkTheme({
                    accentColor: 'linear-gradient(152.11deg, #5c03bc 9%, #e536ab 94.36%)',
                    borderRadius: 'small'
                })}
                chains={chains}
            >
                {children}
            </RainbowKitProvider>
        </WagmiConfig>
    );
};

export default RainbowProvider;
